<template>
  <div class="form-container mx-auto">
    <h2 class="mb-3">Login</h2>

    <b-form @submit.prevent="onLogin">
      <b-form-group 
        id="input-group-1"
        label="Email"
        label-for="input-1"
        label-cols-md="2">
        <b-form-input
          id="input-1"
          v-model="email"
          required
          type="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Password"
        label-for="input-2"
        label-cols-md="2">
        <b-form-input
          id="input-2"
          v-model="password"
          required
          type="password"
        ></b-form-input>
      </b-form-group>

      <div class="clearfix">
        <b-button type="submit" variant="primary" class="mx-1 float-right">Login</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data() {
      return {
        email: '',
        password: ''
      }
    },
    methods: {
      async onLogin(evt) {
        // console.log(this.$store.getters.sessionToken)

        const response = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_API_ROOT}/admin/login`,
          data: { email: this.email, password: this.password }
        });
        this.$store.commit('setSessionToken', response.data.token)

        this.$router.push({ name: 'AdminHome', params: { message: "Logged in successfully" } })
      },
    },
  }
</script>

<style scoped>
.form-container {
  background-color: white;
  padding: 30px;
  width: 40%;
}

</style>